const searchItemReducer = (
  state = {
    contactsSearchString: "",
    tasksSearchString: "",
    callLogsSearchString: "",
    projectSearchString: "",
    reenquiredSearchString: "",
  },
  action: {
    type:
    | "SET_CONTACTS_SEARCH_ITEM"
    | "SET_TASKS_SEARCH_ITEM"
    | "SET_CALLS_SEARCH_ITEM"
    | "SET_PROJECT_SEARCH_ITEM"
    | "SET_REENQUIRED_SEARCH_ITEM";
    payload: any;
  }
) => {
  switch (action.type) {
    case "SET_CONTACTS_SEARCH_ITEM":
      return { ...state, contactsSearchString: action.payload };
    case "SET_TASKS_SEARCH_ITEM":
      return { ...state, tasksSearchString: action.payload };
    case "SET_CALLS_SEARCH_ITEM":
      return { ...state, callLogsSearchString: action.payload };
    case "SET_PROJECT_SEARCH_ITEM":
      return { ...state, projectSearchString: action.payload };
    case "SET_REENQUIRED_SEARCH_ITEM":
      return { ...state, reenquiredSearchString: action.payload };
    default:
      return state;
  }
};

export default searchItemReducer;
