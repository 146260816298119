import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import AuthRoute from "./AuthRoute";
import { checkUser } from "../Services/auth";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";
import { connect, useDispatch} from "react-redux";
import {updateTheme,setconstants} from "../Redux/actions";
import axios from "axios";
import { url } from "../Values/constants";
import { setConstants } from "../Services/resources";
import SignIn from "../Screens/SignIn/SignIn";

const AppRoutes = ({
  userStatus,
  userRole,
  theme,
  authToken
}: {
  userStatus: boolean;
  userRole: string;
  theme: any;
  authToken:any;
}) => {
  
  useEffect(() => {
    if (authToken) {
      setConstants(authToken, dispatcher);
    }

  }, [authToken])

  const dispatcher = useDispatch();

  const setTheme = async () => {
    let isLightMode = await localStorage.getItem("isLightMode") ? localStorage.getItem("isLightMode") : "true";
    if(isLightMode === "true") {
      dispatcher(updateTheme(true))
    }else{
      dispatcher(updateTheme(false))
    }
  }

  useEffect(() => {
    // if(userRole !=="Lead Manager" && userRole !=="Sales" && userRole !=="Team Lead"){
    //   localStorage.removeItem("isLightMode");
    // }
    checkUser(dispatcher);
    setTheme();
    // eslint-disable-next-line
  }, [userRole]);

  // useEffect(() => {
  //   checkUser(dispatcher);
  //   // eslint-disable-next-line
  // }, [userStatus]);

  // useEffect(() => {
  //   fetchUserStatusData(user.uid)
  // }, [organizationUsers]);

//  const fetchUserStatusData = (uid: string) => {
//     firestore
//       .collection("users")
//       .doc(uid)
//       .get()
//       .then((user) => {
//         if (user) {
//           let data: any = user.data();
//           if(data?.status==="ACTIVE")
//           {
//             dispatcher(updateUserStatus(true))
//           }
//           else
//           {
//             dispatcher(updateUserStatus(false))
//           }
//         }
//       })
//   };

  return (
    <Router>
      <Switch>
       <Route exact path="/" component={SignIn} />
       <Route render={() => <Redirect to="/" />} />
       </Switch>
      {/* {userStatus !== undefined && (
        <>
          {userStatus === false && <Route component={AuthRoute} />}
          {userStatus === true && <Route component={HomeRoute} />}
        </>
      )}
      <Route path="/resetPassword" component={PasswordReset} /> */}
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    theme: state.theme.isLightMode,
    authToken:state.user.authToken,
  };
};

export default connect(mapStateToProps)(AppRoutes);
