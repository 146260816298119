import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./SignIn.module.css";
import Logo from "../../Assets/Images/readPro_logo.png";
import background from "../../Assets/Images/background.svg";
import TextInput from "../../Components/TextInput/TextInput";
import SignInTextInput from "../../Components/SignInTextInput/SignInTextInput";
import PasswordInput from "../../Components/Password/PasswordInput";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInFirebase } from "../../Services/auth";
import { emailValidate, passwordValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import Logo2 from "../../Assets/Images/ify_logo2.png";
import LogoNew from "../../Assets/Images/ify_logo_new.png";
import LogoOld from "../../Assets/Images/head.svg";
import Cookies from "universal-cookie";
import LatestBackground from "../../Assets/Images/signin_background_latest.svg";
import AppStore from "../../Assets/Images/appstore.svg";
import PlayStore from "../../Assets/Images/playstore.svg";
import NotFound from "../../Assets/Images/404.jpeg";

type props = {
  history: any;
};

const SignIn: FunctionComponent<props> = ({ history }) => {
  const emailRef: any = useRef();
  const passwordRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [userProfileUid, setUserProfileUid] = useState<any>();
  const cookies = new Cookies();
  cookies.set("uId", userProfileUid);
  const changeLoad = (value: boolean) => {
    setLoad(value);
  };
  const login = async (email: string, password: string) => {
    signInFirebase(
      email,
      password,
      history,
      dispatcher,
      changeLoad,
      setUserProfileUid
    );
  };

  useEffect(()=>{
     setTimeout(() => {
      window.location.href = 'https://read-pro.in/';
    }, 10000);
  },[])


  const newUrl = async () => {
    window.location.href = 'https://read-pro.in/';
  };

  return (
    <div
      className={styles.container}
    >
      <img
        src={LogoNew}
        alt="logo"
        className={styles.logo1} />
      <div style={{ display: "flex", height: "80vh" }}>
        {/* <div className={styles.box1}>
          <img
            src={LatestBackground}
            alt=""
            width="100%"
            height="100%"
          />
        </div> */}
        {/* <div className={styles.box2}>
          <div className={styles.form}>
            <div className={styles.header}>
              <h4 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Welcome</h4>
              <p className={styles.headerText} style={{ fontSize: "14px" }}>Login to continue</p>
            </div>
            <div className={styles.inputs}>
              <SignInTextInput title={"Email"} ref={emailRef} validator={emailValidate}>
                <MdEmail color={"#053535"} />
              </SignInTextInput>
              <PasswordInput
                title={"Password"}
                ref={passwordRef}
                validator={passwordValidate}
                onPress={() =>
                  login(emailRef.current?.value, passwordRef.current?.value)
                }
              >
                <RiLockPasswordFill color={"#053535"} />
              </PasswordInput>
            </div>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={() =>
                  login(emailRef.current?.value, passwordRef.current?.value)
                }
              >
                Login
              </button>
              <div
                className={styles.forgetPassword}
                onClick={() => history.push("/forgetpassword")}
              >
                <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px", color: "black" }}>Forgot Password?</p>
              </div>
            </div>
            <div className={styles.storeIcons}>
              <div className={styles.iconContainer1}>
                <a href="https://play.google.com/store/apps/details?id=com.readpro" target="_blank">
                  <img
                    src={PlayStore}
                    alt={"logo"}
                    width={"25px"}
                  />
                </a>
              </div>
              <div className={styles.iconContainer2}>
                <a href="https://apps.apple.com/in/app/read-pro/id1566457452" target="_blank">
                  <img
                    src={AppStore}
                    alt={"logo"}
                    width={"40px"}
                  />
                </a>
              </div>
            </div>
            <p style={{ textAlign: "center", marginTop: "24px", fontSize: "14px" }}>Powered by</p>
            <div className={styles.socialIcons}>
              <img
                src={Logo2}
                alt={"logo"}
                height={"35px"}
              />
            </div>
          </div>
        </div> */}

<div className={styles.box5}>
          <div className={styles.form}>
            <div className={styles.header}>
              <h4 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Readpro has now moved to another domain.</h4>
              <p style={{ fontFamily: "Poppins",  fontSize: "18px" }}>Click below to access Readpro or you will be redirected to the new domain automatically.</p>
            </div>
            <div className={styles.buttons}>
              <button
                className={styles.button1}
                onClick={() =>
                  newUrl()
                }
              >
                Open Url
              </button>
            </div>
          </div>
        </div>
        {/* <div className={styles.box1}>
          <img
            src={NotFound}
            alt=""
            width="100%"
            height="100%"
          />
        </div> */}
      </div>
    </div>
  );
};

export default SignIn;