export const updateUserStatus = (status: boolean) => {
  return {
    type: "SET_USER_STATUS",
    payload: status,
  };
};

export const showSnackbarAction = (
  message: string,
  severity: "success" | "error" | "warning" | "info"
) => {
  return {
    type: "SHOW_SNACKBAR",
    payload: { open: true, message, severity },
  };
};

export const hideSnackbar = () => {
  return {
    type: "HIDE_SNACKBAR",
    payload: { open: false, message: "", severity: "success" },
  };
};

export const setRole = (role: string) => {
  return {
    type: "SET_ROLE",
    payload: role,
  };
};

export const setFirstLogin = (firstLogin: boolean) => {
  return {
    type: "SET_FIRST_LOGIN",
    payload: firstLogin,
  };
};

export const setUser = (data: any) => {
  return {
    type: "SET_USER",
    payload: data,
  };
};

export const setOrganization = (data: any) => {
  return {
    type: "SET_ORGANIZATION",
    payload: data,
  };
};

export const setOrganizationId = (data: any) => {
  return {
    type: "SET_ID",
    payload: data,
  };
};

export const clearUser = () => {
  return {
    type: "CLEAR_USER",
    payload: {},
  };
};

export const setContacts = (data: any) => {
  return {
    type: "SET_CONTACTS",
    payload: data,
  };
};

export const setFilterObject = (data: {}) => {
  return {
    type: "SET_FILTER_OBJECT",
    payload: data,
  };
};

export const setFilter = (data: {}) => {
  return {
    type: "SET_OBJECT",
    payload: data,
  };
};

export const setRefreshContacts = (data: boolean) => {
  return {
    type: "SET_REFRESH_CONTACTS",
    payload: data,
  };
};

export const setRefreshTasks = (data: boolean) => {
  return {
    type: "SET_REFRESH_TASKS",
    payload: data,
  };
};

export const setRefreshCallLogs = (data: boolean) => {
  return {
    type: "SET_REFRESH_CALL_LOGS",
    payload: data,
  };
};

export const setFilterSort = (data: {}) => {
  return {
    type: "SET_FILTER_SORT",
    payload: data,
  };
};

export const updateTeamsAction = (data: string) => {
  return {
    type: "UPDATE_TEAMS",
    payload: data,
  };
};

export const updateDesignationsAction = (data: string) => {
  return {
    type: "UPDATE_DESIGNATIONS",
    payload: data,
  };
};

export const setOrganizationUsersList = (data: any[]) => {
  return {
    type: "SET_USERS_LIST",
    payload: data,
  };
};

export const setTeamLeadUsersList = (data: any[]) => {
  return {
    type: "SET_TEAMLEAD_USERS_LIST",
    payload: data,
  };
};
export const setBranchLists = (data: any[]) => {
  return {
    type: "SET_BRANCH_LIST",
    payload: data,
  };
};

export const setBranchUsersList = (data: any[]) => {
  return {
    type: "SET_BRANCH_USERS_LIST",
    payload: data,
  };
};

export const setClearFilter = (data: boolean) => {
  return {
    type: "CLEAR_FILTER",
    payload: { clear: data },
  };
};

export const setContactsSearchString = (data: string) => {
  return {
    type: "SET_CONTACTS_SEARCH_ITEM",
    payload: data,
  };
};

export const setTasksSearchString = (data: string) => {
  return {
    type: "SET_TASKS_SEARCH_ITEM",
    payload: data,
  };
};

export const setCallsSearchString = (data: string) => {
  return {
    type: "SET_CALLS_SEARCH_ITEM",
    payload: data,
  };
};
export const setProjectSearchString = (data: string) => {
  return {
    type: "SET_PROJECT_SEARCH_ITEM",
    payload: data,
  };
};
export const setTotal = (data: number) => {
  return {
    type: "SET_TOTAL",
    payload: data,
  };
};

export const setCompleted = (data: number) => {
  return {
    type: "SET_COMPLETED",
    payload: data,
  };
};

export const updateStage = (data: {}) => {
  return {
    type: "UPDATE_STAGE",
    payload: data,
  };
};
export const feedbackReportData = (data: any[]) => {
  return {
    type: "FEEDBACK_REPORT_DATA",
    payload: data,
  };
};
export const interestedBudgetReportData = (data: any[]) => {
  return {
    type: "INTERESTED_BUDGET_REPORT_DATA",
    payload: data,
  };
};
export const interestedLocationReportData = (data: any[]) => {
  return {
    type: "INTERESTED_LOCATION_REPORT_DATA",
    payload: data,
  };
};
export const interestedProjectReportData = (data: any[]) => {
  return {
    type: "INTERESTED_PROJECT_REPORT_DATA",
    payload: data,
  };
};
export const interestedPropertyTypeReportData = (data: any[]) => {
  return {
    type: "INTERESTED_PROPERTY_TYPE_REPORT_DATA",
    payload: data,
  };
};
export const interestedPropertyStageReportData = (data: any[]) => {
  return {
    type: "INTERESTED_PROPERTY_STAGE_REPORT_DATA",
    payload: data,
  };
};
export const lostReasonReportData = (data: any[]) => {
  return {
    type: "LOST_REASON_REPORT_DATA",
    payload: data,
  };
};
export const notInterestedReportData = (data: any[]) => {
  return {
    type: "NOT_INTERESTED_REPORT_DATA",
    payload: data,
  };
};

export const completeTaskReportData = (data: any[]) => {
  return {
    type: "COMPLETE_TASK_REPORT_DATA",
    payload: data,
  };
};

export const pendingReportData = (data: any[]) => {
  return {
    type: "PENDING_TASK_REPORT_DATA",
    payload: data,
  };
};

export const overDueReportData = (data: any[]) => {
  return {
    type: "OVERDUE_TASK_REPORT_DATA",
    payload: data,
  };
};
export const callBackReasonReportData = (data: any[]) => {
  return {
    type: "CALL_BACK_REASON_REPORT_DATA",
    payload: data,
  };
};
export const callBackReportData = (data: any[]) => {
  return {
    type: "CALL_BACK_REPORT_DATA",
    payload: data,
  };
};

export const updateTheme = (data: any) => {
  return {
    type: "UPDATE_THEME",
    payload: data,
  };
};

export const setDeactivationAlert = (data: any) => {
  return {
    type: "SET_DEACTIVATION_ALERT",
    payload: data,
  };
};

export const setPaymentStatus = (data: any) => {
  return {
    type: "SET_PAYMENT_STATUS",
    payload: data,
  };
};

export const setOrganizationData = (data: any) => {
  return {
    type: "SET_ORGANIZATION_DATA",
    payload: data,
  };
};

export const clearAllFilters = (data: {}) => {
  return {
    type: "CLEAR_ALL_FILTERS",
    payload: data,
  };
};

export const setAuthToken = (data: any) => {
  return {
    type: "SET_AUTHTOKEN",
    payload: data,
  };
};

export const setUserFilter = (data: {}) => {
  return {
    type: "SET_USER_FILTER_OBJECT",
    payload: data,
  };
};
export const setTaskFilter = (data: {}) => {
  return {
    type: "SET_TASK_FILTER_OBJECT",
    payload: data,
  };
};
export const setCallLogFilter = (data: {}) => {
  return {
    type: "SET_CALLLOG_FILTER_OBJECT",
    payload: data,
  };
};
export const setCallLogDrillDownFilter = (data: {}) => {
  return {
    type: "SET_CALLLOGDRILLDOWN_FILTER_OBJECT",
    payload: data,
  };
};
export const setContactDrillDownFilter = (data: {}) => {
  return {
    type: "SET_CONTACTDRILLDOWN_FILTER_OBJECT",
    payload: data,
  };
};
export const setTaskDrillDownFilter = (data: {}) => {
  return {
    type: "SET_TASKDRILLDOWN_FILTER_OBJECT",
    payload: data,
  };
};
// -----------------------------------------------------------------------------------
export const setUserFilterSort = (data: {}) => {
  return {
    type: "SET_USER_FILTER_SORT",
    payload: data,
  };
};
export const setTaskFilterSort = (data: {}) => {
  return {
    type: "SET_TASK_FILTER_SORT",
    payload: data,
  };
};
export const setCallLogFilterSort = (data: {}) => {
  return {
    type: "SET_CALLLOG_FILTER_SORT",
    payload: data,
  };
};
export const setContactDrillDownFilterSort = (data: {}) => {
  return {
    type: "SET_CONTACTDRILLDOWN_FILTER_SORT",
    payload: data,
  };
};
export const setTaskDrillDownFilterSort = (data: {}) => {
  return {
    type: "SET_TASKDRILLDOWN_FILTER_SORT",
    payload: data,
  };
};
export const setCallLogDrillDownFilterSort = (data: {}) => {
  return {
    type: "SET_CALLLOGDRILLDOWN_FILTER_SORT",
    payload: data,
  };
};
export const setReenquiredFilter = (data: {}) => {
  return {
    type: "SET_REENQUIRED_FILTER_OBJECT",
    payload: data,
  };
};
export const setReenquiredFilterSort = (data: {}) => {
  return {
    type: "SET_REENQUIRED_FILTER_SORT",
    payload: data,
  };
};
export const setReenquiredSearchString = (data: string) => {
  return {
    type: "SET_REENQUIRED_SEARCH_ITEM",
    payload: data,
  };
};

export const setconstants = (data: {}) => {
  return {
    type: "SET_CONSTANTS",
    payload: data,
  };
}
export const setUserPreference = (data: {}) => {
  return {
    type: "SET_USER_PREFERENCE",
    payload: data,
  };
}

export const setLeadDistributionFilter =(data:{})=>{
  return{
    type:"SET_LEAD_DISTRIBUTION_FILTER_OBJECT",
    payload:data
  }
}

export const setLeadDistributionSortFilter =(data:{})=>{
  return{
    type:"SET_LEAD_DISTRIBUTION_FILTER_SORT",
    payload:data
  }
}
// ----------------------------------------------------------------
// export const setCallLogDrillDownClearFilter = (data: boolean) => {
//   return {
//     type: "CALLLOGDRILLDOWN_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };
// export const setContactDrillDownClearFilter = (data: boolean) => {
//   return {
//     type: "CONTACTDRILLDOWN_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };
// export const setTaskDrillDownClearFilter = (data: boolean) => {
//   return {
//     type: "TASKDRILLDOWN_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };
// export const setUserClearFilter = (data: boolean) => {
//   return {
//     type: "USER_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };
// export const setTaskClearFilter = (data: boolean) => {
//   return {
//     type: "TASK_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };
// export const   setCallLogClearFilter
// = (data: boolean) => {
//   return {
//     type: "CALLLOG_CLEAR_FILTER",
//     payload: { clear: data },
//   };
// };