const callLogDrillDownFilterObjectReducer = (
    state = {},
    action: {
      type: "SET_CALLLOGDRILLDOWN_FILTER_OBJECT" | "CLEAR_ALL_USER_FILTERS";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "SET_CALLLOGDRILLDOWN_FILTER_OBJECT":
        return { ...state, ...action.payload };
      case "CLEAR_ALL_USER_FILTERS":
        return {};
      default:
        return state;
    }
  };
  
  export default callLogDrillDownFilterObjectReducer;
  