import { combineReducers } from "redux";
import branchListReducer from "./branchReducer";
import branchUsersReducer from "./branchUsersReducer";
// import userclearFilterReducer from "./userclearFilterReducer";
// import taskclearFilterReducer from "./taskclearFilterReducer";
// import callLogclearFilterReducer from "./callLogclearFilterReducer";
// import contactDrillDownclearFilterReducer from "./contactDrillDownclearFilterReducer";
// import callLogDrillDownclearFilterReducer from "./callLogDrillDownclearFilterReducer";
// import taskDrillDownclearFilterReducer from "./taskDrillDownclearFilterReducer";
import clearFilterReducer from "./clearFilterReducer";
import contactsReducer from "./contactsReducer";
import filterObjectReducer from "./filterObjectReducer";
import userFilterObjectReducer from "./userFilterObjectReducer";
import taskFilterObjectReducer from "./taskFilterObjectReducer";
import callLogFilterObjectReducer from "./callLogFilterObjectReducer";
import callLogDrillDownFilterObjectReducer from './callLogDrillDownFilterObjectReducer';
import contactDrillDownFilterObjectReducer from './contactDrillDownFilterObjectReducer';
import taskDrillDownFilterObjectReducer from './taskDrillDownFilterObjectReducer';
import filterReducer from "./filterReducer";
import filterSortReducer from "./filterSortReducer";
import callLogDrillDownfilterSortReducer from "./callLogDrillDownfilterSortReducer";
import callLogfilterSortReducer from "./callLogfilterSortReducer";
import contactDrillDownfilterSortReducer from "./contactDrillDownfilterSortReducer";
import contactfilterSortReducer from "./contactfilterSortReducer";
import taskDrillDownfilterSortReducer from "./taskDrillDownfilterSortReducer";
import taskfilterSortReducer from "./taskfilterSortReducer ";
import userfilterSortReducer from "./userfilterSortReducer";
import organizationReducer from "./organizationReducer";
import organizationUserReducer from "./organizationUserReducer";
import progressReducer from "./progressReducer";
import refreshReducer from "./refreshReducer";
import searchItemReducer from "./searchItemReducer";
import snackbarReducer from "./snackbarReducer";
import stageReducer from "./stageReducer";
import teamLeadUsersReducer from "./teamLeadUsersReducer";
import userReducer from "./userReducer";
import themeReducer from "./themeReducer";
import { interestedBudgetReportReducer, 
  feedbackReportReducer,
  interestedLocationReportReducer,
  interesteProjectReportReducer,
  interestedPropertyTypeReportReducer,
  interestedPropertyStageReportReducer,
  lostReasonReportReducer,
  notInterestedReportReducer,
  completedTaskReportReducer,
  pendingTaskReportReducer,
  overDueReportReducer,
  callBackReasonReportReducer,
  callLogsReportReducer
} from './reportsReducer'
import reenquiredFilterSortReducer from "./reenquiredFilterSortReducer";
import reenquiredFilterObjectReducer from "./reenquiredFilterObjectReducer";
import constants from "./constantsReducer";
import userPreference from './userPreferenceReducer'
import leadDistributionFilter from "./leadDistributionFilterReducer";
import leadDistributionSortReducer from "./leadDistributionFilterSortReducer";


const allReducer = combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
  contacts: contactsReducer,
  organization: organizationReducer,
  filterObject: filterReducer,
  organizationUsers: organizationUserReducer,
  teamLeadUsers: teamLeadUsersReducer,
  clearFilter: clearFilterReducer,
  // userclearFilter: userclearFilterReducer,
  // taskclearFilter: taskclearFilterReducer,
  // callLogclearFilter: callLogclearFilterReducer,
  // contactDrillDownclearFilter: contactDrillDownclearFilterReducer,
  // taskDrillDownclearFilter: taskDrillDownclearFilterReducer,
  // callLogDrillDownclearFilter: callLogDrillDownclearFilterReducer,
  progress: progressReducer,
  leadsStage: stageReducer,
  branchList: branchListReducer,
  branchUsers: branchUsersReducer,
  filter: filterObjectReducer,
  userFilter: userFilterObjectReducer,
  taskFilter: taskFilterObjectReducer,
  callLogFilter: callLogFilterObjectReducer,
  callLogDrillDownFilter: callLogDrillDownFilterObjectReducer,
  contactDrillDownFilter:contactDrillDownFilterObjectReducer,
  TaskDrillDownFilter:taskDrillDownFilterObjectReducer,
  filterSort: filterSortReducer,
  userfilterSort: userfilterSortReducer,
  contactfilterSort: contactfilterSortReducer,
  taskfilterSort: taskfilterSortReducer,
  callLogfilterSort: callLogfilterSortReducer,
  contactDrillDownfilterSort: contactDrillDownfilterSortReducer,
  taskDrillDownfilterSort: taskDrillDownfilterSortReducer,
  callLogDrillDownfilterSort: callLogDrillDownfilterSortReducer,
  refresh: refreshReducer,
  searchItem: searchItemReducer,
  feedbackReportData: feedbackReportReducer,
  interestedBudgetdata: interestedBudgetReportReducer,
  interestedLocationdata: interestedLocationReportReducer,
  interesteProjectData:interesteProjectReportReducer,
  interestedPropertyTypeData:interestedPropertyTypeReportReducer,
  interestedPropertyStageData:interestedPropertyStageReportReducer,
  lostReasonReportData:lostReasonReportReducer,
  notInterestedReportData: notInterestedReportReducer,
  completedTaskReportData:completedTaskReportReducer,
  pendingTaskReportData:pendingTaskReportReducer,
  overDueReportData:overDueReportReducer,
  callBackReasonReportData:callBackReasonReportReducer,
  callLogReportData:callLogsReportReducer,
  theme: themeReducer,
  reenquiredFilter: reenquiredFilterObjectReducer,
  reenquiredFilterSort: reenquiredFilterSortReducer,
  constants:constants,
  userPreference:userPreference,
  leadDistributionFilter:leadDistributionFilter,
  leadDistributionSort:leadDistributionSortReducer

});

export default allReducer;
