import { auth, firestore, functions } from "../Firebase";
import {
  setRole,
  showSnackbarAction,
  updateUserStatus,
  setUser,
  clearUser,
  setOrganization,
  setOrganizationId,
  setFirstLogin,
  setPaymentStatus
} from "../Redux/actions";
import { emailValidate, passwordValidate } from "../Values/validatorsnew";
import { url } from "../Values/constants";
import axios from "axios";


export const handleUser = (organizationId: any,user: any,dispatcher: any) => {
  const subscriber = firestore
    .collection('organizations')
  .doc(organizationId)
    .onSnapshot((data) => {
      if (data === null) {
        return;
      }
      const orgData = data.data();
      if (orgData) {
        // dispatcher(updateUser(data));
        // setUsersList({...user, ...data}, dispatcher);
        if (orgData.payment_status === false) {
          // firestore()
          //   .collection('users')
          //   .doc(user.uid).get().then((user)=>{
          //     let data = user.data();
          //     if(data && data.status === 'INACTIVE'){
          //       signOutFirebase(data.uid, navigation, dispatcher);
          //       Alert.alert('Inactive User', 'Please Contact Your Admin', [
          //         {
          //           text: 'Okay',
          //           onPress: () => {
          //             return;
          //           },
          //         },
          //       ]);
          //     }
          //   })
          if(user.profile !== "Lead Manager"){
            dispatcher(showSnackbarAction("Your readpro subscription has expired, Please contact your admin", "error"));
            dispatcher(setPaymentStatus({paymentStatus:false}));
            auth.signOut();
          }else{
            dispatcher(setPaymentStatus({paymentStatus:false}));
          }
        } else{
          dispatcher(setPaymentStatus({paymentStatus:true}));
        }
      }
    });
  return subscriber;
};

export const checkUser = (dispatcher: any) => {
  auth.onAuthStateChanged(function (user) {
    
    if (user) {
      user.getIdTokenResult().then((result) => {
        if (
          result.claims.firstLogin === true ||
          result.claims.firstLogin === undefined
        ) {
          dispatcher(updateUserStatus(false));
          dispatcher(setFirstLogin(true));
        } else {
          if (result.claims.role) {
            if (result.claims.role === "superAdmin") {
              dispatcher(
                setUser({
                  user_first_name: "SuperAdmin",
                  user_last_name: "",
                  user_email: user.email,
                })
              );
            }
            if (result.claims.role === "organization") {
              if (user.email) {
                fetchOrganizationData(user.email, dispatcher);
              }
            } else if (result.claims.role !== "superAdmin") {
              fetchUserData(user.uid, dispatcher);
            }

            dispatcher(setRole(result.claims.role));
            dispatcher(updateUserStatus(true));
            dispatcher(setFirstLogin(false));
          } else {
            dispatcher(setRole("unauthorized"));
            auth.signOut();
            dispatcher(showSnackbarAction("Unauthorized User !!", "error"));
          }
        }
      });
    } else {
      dispatcher(setFirstLogin(false));
      dispatcher(clearUser());
      dispatcher(updateUserStatus(false));
    }
  });
};

export const fetchOrganizationData = (email: string, dispatcher: any) => {
  firestore
    .collection("organizations")
    .where("admin_email_id", "==", email)
    .get()
    .then((organization) => {
      if (organization) {
        const data = organization.docs[0].data();
        if (data.status === "ACTIVE") {
          dispatcher(setOrganization(organization.docs[0].data()));
          dispatcher(setOrganizationId(organization.docs[0].id));
          dispatcher(
            setUser({
              user_first_name: data.admin_first_name,
              user_last_name: data.admin_last_name,
              user_email: data.admin_email_id,
            })
          );
        } else {
          dispatcher(
            showSnackbarAction("User is INACTIVE! Contact Admin!!", "error")
          );
          auth.signOut();
        }
      } else {
        console.log("org not found");
      }
    })
    .catch((err) => console.log("fetch organization error", err));
};

export const fetchUserData = (uid: string, dispatcher: any) => {
  firestore
    .collection("users")
    .doc(uid)
    .onSnapshot((user) => {
      if (user) {
        let data: any = user.data();
        // console.log("user.orggggggggggggggggggggggggggggg",data)
        if (data.status === "ACTIVE") {
          firestore
            .collection("organizations")
            .doc(data.organization_id)
            .onSnapshot((org)=>{
              let orgData: any = org.data();
              if (data.profile!=="Sales" && data.profile!=="Team Lead")
              {
                const userData = {
                  ...data,
                  current_active_status: orgData?.current_active_status,
                  is_approval_enabled: orgData?.is_approval_enabled,
                  is_shift_activation_enable: orgData?.is_shift_activation_enable
                };
               dispatcher(setUser(userData));
              }
              else if (orgData?.current_active_status === true) {
                const userData = {
                  ...data,
                  current_active_status: orgData?.current_active_status,
                  is_approval_enabled: orgData?.is_approval_enabled,
                  is_shift_activation_enable: orgData?.is_shift_activation_enable
                };
                dispatcher(setUser(userData));
              } else {
                auth.signOut();
                dispatcher(
                  showSnackbarAction("Organization is currently INACTIVE! Please Contact Your Admin!!", "error")
                );
              }
            })
          // dispatcher(setUser(user.data()));
        } else {
          auth.signOut();
          dispatcher(
            showSnackbarAction("User is INACTIVE! Contact Admin!!", "error")
          );
        }
      } else {
        console.log("user not found");
      }
    })
    // .catch(() => console.log("fetch user error"));
};

export const signInFirebase = async (
  email: string,
  password: string,
  history: any,
  dispatcher: any,
  setLoad: (value: boolean) => void,
  setUserProfileUid:any
) => {
  let check = emailValidate(email);
  if (check.length === 0) {
    check = passwordValidate(password);
  }

  if (check.length === 0) {
    setLoad(true);
    try {
      if (email && password) {
       const useData= await auth.signInWithEmailAndPassword(email, password);
       setUserProfileUid(useData?.user?.uid)
        setLoad(false);
      }
    } catch (error: any) {
      let errorMsg = "";
      console.log("Sign In Error", error);
      if (error.code === "auth/wrong-password") {
        errorMsg = "Invalid Password";
      } else if (error.code === "auth/user-not-found") {
        errorMsg = "User Not Found";
      } else {
        errorMsg = "Error! Try Again";
      }

      dispatcher(showSnackbarAction(errorMsg, "error"));
      setLoad(false);
    }
  } else {
    dispatcher(showSnackbarAction(check, "error"));
    setLoad(false);
  }
};

export const resetPassword = async (
  password: string,
  confirmPassword: string,
  dispatcher: any,
  oobCode: any,
  setLoad: (value: boolean) => void,
  setReset: (value: boolean) => void,
  firstLogin: boolean,
  history: any
) => {
  if (password === "" || confirmPassword === "") {
    dispatcher(showSnackbarAction("Please Enter Password", "error"));
  } else if (password !== confirmPassword) {
    dispatcher(showSnackbarAction("Password does not match", "error"));
  } else {
    if (oobCode) {
      try {
        setLoad(true);
        await auth.confirmPasswordReset(oobCode, confirmPassword);
        setLoad(false);
        setReset(true);
      } catch (e) {
        console.log("error:", e);
        setLoad(false);
      }
    } else if (firstLogin === true) {
      setLoad(true);
      const user = auth.currentUser;
      user
        ?.updatePassword(password)
        .then(() => {
          functions
            .httpsCallable("setFirstLogin")()
            .then(() => {
              dispatcher(showSnackbarAction("Password Updated !!", "success"));
              auth.signOut().then(() => {
                setLoad(false);
                history.push("/");
              });
            });
        })
        .catch((error) => {
          setLoad(false);
          console.log("password reset error", error);
          auth.signOut();
          dispatcher(
            showSnackbarAction("Login Again to Reset Password!!", "error")
          );

          history.push("/");
        });
    }
  }
};

export const updatePassword = async (
  oldPassword:string,
  newPassword: string,
  userEmail:string,
  dispatcher: any,
  // oobCode: any,
  setLoad: (value: boolean) => void,
  // setReset: (value: boolean) => void,
  // firstLogin: boolean,
  history: any,
  authToken:any,
) => {
  try{
    const bodyData = {
      old_password:oldPassword,
      new_password:newPassword,
      user_email:userEmail,
  }
  axios.defaults.headers.common['x-access-token'] =
          authToken
    const res = await axios.post(url + '/updateUserPassword', bodyData);
    setLoad(false);
    history.push("/");
    dispatcher(
      showSnackbarAction("Password Updated", "success")
    );
  }catch (error) {
    setLoad(false);
    dispatcher(
      showSnackbarAction("Wrong Password", "error")
    );
  }
  
};
