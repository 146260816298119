const themeReducer = (
    state = {
      isLightMode: true,
    },
    action: {
      type: "UPDATE_THEME";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "UPDATE_THEME":
        return { ...state, isLightMode: action.payload };
  
      default:
        return state;
    }
  };
  
  export default themeReducer;
  